
import TimesIcon from "./icons/TimesIcon.vue";
import IconsDrawer from "./icons/Drawer.vue";
import SignInModal from "~/modals/SignInModal.vue";
import SignUpModal from "~/modals/SignUpModal.vue";

export default {
  components: {
    TimesIcon,
    IconsDrawer,
  },
  props: {
    componentName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isTransition: true,
      isMobileDrawer: false,
      TrTimeout: null,
      emitTimeout: null,
    };
  },
  computed: {
    component() {
      let compName;

      switch (this.componentName) {
        case "SignInModal":
          compName = SignInModal;
          break;
        case "SignUpModal":
          compName = SignUpModal;
          break;
        default:
          break;
      }

      return compName;
    },
  },
  mounted() {
    this.TrTimeout = setTimeout(() => {
      this.isTransition = false;
      clearTimeout(this.TrTimeout);
    }, 200);

    if (window.innerWidth >= 768) {
      this.isMobileDrawer = false;
    } else {
      this.isMobileDrawer = true;
    }
  },
  beforeDestroy() {
    if (this.TrTimeout) {
      clearTimeout(this.TrTimeout);
    }
    if (this.emitTimeout) {
      clearTimeout(this.emitTimeout);
    }
  },
  methods: {
    close() {
      this.isTransition = true;
      this.emitTimeout = setTimeout(() => {
        this.$parent.$emit("close");
        this.$router.replace({ query: null });
        clearTimeout(this.emitTimeout);
      }, 50);
    },
  },
};
