
import InfoCircleIcon from "../components/icons/InfoCircleIcon.vue";

export default {
  components: { InfoCircleIcon },
  data() {
    return {
      email: "",
      sent: false,
      loading: false,
      errors: {},
      message: null,
      timeoutId: null,
      ldTimeoutId: null,
    };
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    if (this.ldTimeoutId) {
      clearTimeout(this.ldTimeoutId);
    }
  },
  methods: {
    async submit() {
      this.loading = true;
      this.errors = {};

      try {
        await this.$axios
          .$post("api/auth/password/create", {
            email: this.email,
            country: this.$store.state.country,
            locale: this.$store.state.locale,
          })
          .then((response) => {
            this.$toast.show(response.data);

            this.timeoutId = setTimeout(() => {
              this.loading = false;
              this.sent = true;
            }, 300);
          });
      } catch (error) {
        console.error(error);
        if (!error.response.data) {
          throw error;
        } else {
          this.errors = error.response.data.errors;
          this.message = error.response.data.message;
        }

        this.ldTimeoutId = setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
  },
};
