import { render, staticRenderFns } from "./ResetAccountPasswordModal.vue?vue&type=template&id=da0d7dc2"
import script from "./ResetAccountPasswordModal.vue?vue&type=script&lang=js"
export * from "./ResetAccountPasswordModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DrawerModal: require('/usr/src/app/components/DrawerModal.vue').default})
