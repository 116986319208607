
import InfoCircleIcon from "../components/icons/InfoCircleIcon.vue";
import SignUpModal from "./SignUpModal.vue";
import ResetAccountPasswordModal from "./ResetAccountPasswordModal.vue";
import ResetPasswordModal from "~/modals/ResetPasswordModal";

export default {
  components: { InfoCircleIcon },
  props: {
    accountEmail: {
      type: String,
      default: "",
    },
  },
  asyncData({ query, store, error }) {
    if (!store.state.enableShop) {
      error(404);
    }

    return {
      email: query.email || null,
    };
  },
  data() {
    return {
      loading: false,
      errors: {},
      email: this.accountEmail || null,
      password: null,
      showPassword: false,
      message: null,
    };
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
  methods: {
    openResetAccountModal() {
      this.$emit("close");
      this.$modal.show(
        ResetAccountPasswordModal,
        {},
        {
          height: "auto",
        }
      );
    },
    openResetPasswordModal() {
      this.$emit("close");
      this.$modal.show(
        ResetPasswordModal,
        {},
        {
          height: "auto",
        }
      );
    },
    openSignUpModal() {
      this.$emit("close");
      this.$modal.show(SignUpModal, {
        clickToClose: true,
      });
    },
    async onSubmit() {
      this.loading = true;
      this.errors = {};

      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
          remember_me: true,
        });

        // this.$toast.info('Logged In 🚀')
        if (window.gtag) {
          await window.gtag("event", "login");
        }
        if (this.$intercom) {
          this.$intercom.update({
            user_id: this.$store.state.auth.user.id,
            name: this.$store.state.auth.user.given_name,
            email: this.$store.state.auth.user.email,
          });
        }

        this.$toast.show("Signin successfully!");

        const redirect = this.$cookies.get("Redirect");

        await this.$router.push({ path: this.$i18nPath("/") });

        if (redirect && redirect !== this.$route.fullPath) {
          this.$emit("close");
        } else {
          this.$emit("close");
        }
      } catch (e) {
        if (!e.response.data) {
          throw e;
        } else if (e.response && e.response.status === 403) {
          this.errors = {
            email: [e.response.data.message],
          };
        } else {
          this.errors = e.response.data.errors;
          this.message = e.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },
  },
  head() {
    return { title: this.$t("common.login") };
  },
};
