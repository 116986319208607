
import InfoCircleIcon from "../components/icons/InfoCircleIcon.vue";
import SignInModal from "./SignInModal.vue";

export default {
  components: { InfoCircleIcon },
  props: {
    token: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  middleware: "guest",
  data() {
    return {
      password: "",
      password_confirmation: "",
      errors: {},
      message: null,
      timeoutId: null,
      loading: false,
      showPassword: false,
      showRepeatPassword: false,
    };
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        await this.$axios.$post("api/auth/password/reset", {
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          token: this.token,
        });

        this.$toast.show("Account password reset successfully.");

        await this.$router.push({ path: this.$i18nPath("/") });

        this.$emit("close");

        this.$modal.show(SignInModal, {
          accountEmail: this.email,
        });
      } catch (error) {
        if (!error.response.data) {
          console.error(error);
        } else if (error.response && error.response.status === 403) {
          this.errors = {
            password: [error.response.data.message],
          };
        } else {
          this.errors = error.response.data.errors;
          this.message = error.response.data.message;
        }

        this.timeoutId = setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
  },
  head() {
    return { title: this.$t("common.create_new_password") };
  },
};
