
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import InfoCircleIcon from "../components/icons/InfoCircleIcon.vue";
import SignInModal from "./SignInModal.vue";

export default {
  components: {
    InfoCircleIcon,
    VueHcaptcha,
  },
  middleware: "guest",
  asyncData({ store, error }) {
    if (!store.state.enableShop) {
      error(404);
    }
  },
  data() {
    return {
      loading: false,
      given_name: null,
      family_name: null,
      email: null,
      password: null,
      password_confirmation: null,
      errors: {},
      showPassword: false,
      showRepeatPassword: false,
      message: null,
      accept_terms: false,
    };
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
  },
  methods: {
    async onVerify(token, ekey) {
      if (this.accept_terms === false) {
        this.$toast.error(this.$t("common.accept_terms_condition"));
        this.loading = false;
      } else {
        try {
          await this.$axios.$post("api/auth/signup", {
            given_name: this.given_name,
            family_name: this.family_name,
            email: this.email,
            company: this.$store.state.country,
            password: this.password,
            password_confirmation: this.password_confirmation,
            "h-captcha-response": token,
          });

          this.loading = false;

          if (window.gtag) {
            window.gtag("event", "sign_up");
          }

          this.$toast.show(this.$t("common.signup_success"));

          await this.$router.push({ path: this.$i18nPath("/") });

          this.$emit("close");

          this.$modal.show(SignInModal, {
            accountEmail: this.email,
          });
        } catch (e) {
          this.errors = e.response.data.errors;
          this.message = e.response.data.message;
          this.loading = false;
        }
      }
    },
    onExpire() {
      // this.$toast.error('Captcha expired')
      console.log("[vue-htcapcha] Expired token");
      this.sending = false;
    },
    onError() {
      console.log("[vue-htcapcha] Failed to load hcaptcha");
      this.sending = false;
    },
    openSignInModal() {
      this.$emit("close");
      this.$modal.show(SignInModal, {
        clickToClose: true,
      });
    },
    onSubmit() {
      this.errors = {};
      this.loading = true;
      this.$refs.invisibleHcaptcha.execute();
    },
  },
  head() {
    return { title: this.$t("common.register") };
  },
};
